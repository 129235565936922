import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const AboutPreview = () => {
  return (
    <section className="about-preview py-5 bg-light">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <h2>About TUNISCONS</h2>
            <p className="lead">Liberia's Premier Security Solutions Provider</p>
            <p>
              Established in 2019, TUNISCONS is a 100% Liberian-owned private security company 
              bringing together elite professionals from the Armed Forces of Liberia (AFL), 
              Executive Protective Service (EPS), and Liberian National Police (LNP). With over 
              350 trained security guards and a proven track record serving businesses, embassies, 
              and private clients across Liberia, we're your trusted partner in comprehensive 
              security solutions.
            </p>
            <Link to="/about" className="btn btn-primary">Learn More About Us</Link>
          </Col>
          <Col lg={6}>
            <div className="about-image">
              <img 
                src={process.env.PUBLIC_URL + '/images/logo2.jpg'} 
                alt="TUNISCONS Security Team" 
                className="img-fluid rounded shadow-lg" 
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutPreview;
