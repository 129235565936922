import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    '/images/team-0x.png',
    '/images/team-1x.png',
    '/images/team-2x.png'
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <header 
      className="hero"
      style={{ backgroundImage: `url(/images/back-background.jpg)` }}
    >
      <div className="hero-background">
        <div className="slideshow">
          {slides.map((slide, index) => (
            <img
              key={index}
              src={process.env.PUBLIC_URL + slide}
              alt="Security Services"
              className={`hero-bg-image fade ${index === currentSlide ? 'active' : ''}`}
            />
          ))}
        </div>
      </div>
      <Container className="h-100">
        <Row className="h-100 align-items-center">
          <Col lg={6}>
            <div className="hero-text">
              <h1 className="display-4 mb-4">The Unit Security & Consultancy Services</h1>
              <p className="lead mb-4">
                Protecting your assets with cutting-edge security services and expert consultancy.
              </p>
              <div className="hero-buttons">
                <Link to="/services" className="btn btn-primary btn-lg me-3">
                  Our Services
                </Link>
                <Link to="/contact" className="btn btn-outline-light btn-lg">
                  Contact Us
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Hero;
