import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faProjectDiagram, faClock, faAward } from '@fortawesome/free-solid-svg-icons';

const Statistics = () => {
  const stats = [
    {
      icon: faUsers,
      count: 100,
      label: 'Happy Clients'
    },
    {
      icon: faProjectDiagram,
      count: 1000,
      label: 'Missions Completed'
    },
    {
      icon: faClock,
      count: 5,
      label: 'Years Experience'
    },
    {
      icon: faAward,
      count: 350,
      label: 'Trained Officers'
    }
  ];

  return (
    <section className="statistics py-5 bg-dark text-white">
      <Container>
        <Row>
          {stats.map((stat, index) => (
            <Col md={3} className="text-center" key={index}>
              <div className="stat-item">
                <FontAwesomeIcon icon={stat.icon} className="stat-icon" />
                <h3 className="counter">{stat.count}</h3>
                <p>{stat.label}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Statistics;
