import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";
import axios from 'axios';

const Gallery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      // Local development URL
      // const response = await axios.get('http://localhost:5000/api/gallery');
      
      // Production URL
      const response = await axios.get('https://tunisconsbackend.onrender.com/api/gallery');
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching gallery images:', error);
    }
  };

  const slides = images.map(img => ({
    // Local development URL
    // src: `http://localhost:5000${img.image}`,
    
    // Production URL
    src: `https://tunisconsbackend.onrender.com${img.image}`,
    description: img.caption || ''
  }));

  const headerStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/images/back-background.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingTop: '160px',
    paddingBottom: '80px',
    marginTop: '0'
  };

  return (
    <div className="gallery-page">
      {/* Page Header */}
      <header className="page-header" style={headerStyle}>
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h1>Our Gallery</h1>
              <p className="lead">Explore our security operations and team in action</p>
            </Col>
          </Row>
        </Container>
      </header>

      {/* Gallery Grid */}
      <section className="gallery-grid py-5">
        <Container>
          <Row className="g-4">
            {images.map((image, idx) => (
              <Col xs={12} sm={6} md={4} lg={3} key={image._id}>
                <div 
                  className="gallery-item"
                  onClick={() => {
                    setIndex(idx);
                    setIsOpen(true);
                  }}
                >
                  <img 
                    // Local development URL
                    // src={`http://localhost:5000${image.image}`}
                    
                    // Production URL
                    src={`https://tunisconsbackend.onrender.com${image.image}`}
                    alt={image.caption || `Gallery item ${idx + 1}`}
                    className="img-fluid"
                  />
                  <div className="gallery-overlay">
                    <i className="fas fa-search-plus"></i>
                  </div>
                  {image.caption && (
                    <div className="gallery-caption">
                      {image.caption}
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <style jsx>{`
        .gallery-item {
          position: relative;
          cursor: pointer;
          overflow: hidden;
          border-radius: 8px;
        }
        
        .gallery-item img {
          transition: transform 0.3s ease;
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 1/1;
        }
        
        .gallery-item:hover img {
          transform: scale(1.05);
        }
        
        .gallery-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          transition: opacity 0.3s ease;
        }
        
        .gallery-overlay i {
          color: white;
          font-size: 24px;
        }
        
        .gallery-item:hover .gallery-overlay {
          opacity: 1;
        }
        
        .gallery-caption {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 10px;
          background: rgba(0, 0, 0, 0.7);
          color: white;
          font-size: 14px;
          text-align: center;
        }
      `}</style>

      {/* Lightbox */}
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        index={index}
        slides={slides}
        styles={{ container: { zIndex: 9999 } }}
      />
    </div>
  );
};

export default Gallery;
