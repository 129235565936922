import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faShieldAlt,
  faPen,
  faWalking,
  faUserTie,
  faExclamationTriangle,
  faFireExtinguisher,
  faHeartbeat,
  faBroadcastTower,
  faHandshake,
  faHandRock,
  faSprayCan,
  faBook,
  faUserShield,
  faFistRaised,
  faDoorOpen,
  faUsersCog,
  faCar,
  faEye,
  faSearch,
  faClock,
  faClipboardCheck,
  faUserCheck,
  faGraduationCap,
  faSync,
  faCheck
} from '@fortawesome/free-solid-svg-icons';

const Careers = () => {
  const headerStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/images/back-background.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };

  const requirements = [
    'Academic Qualification (Minimum High School Education)',
    'Police Clearance',
    'Physical & Mental Health Proof',
    'Age 18 and Above',
    'Liberian Citizenship or Valid Work Permit'
  ];

  const basicTraining = [
    { icon: faShieldAlt, text: 'Introduction to Industrial Security' },
    { icon: faPen, text: 'Report Writing' },
    { icon: faWalking, text: 'Patrols' },
    { icon: faUserTie, text: 'Discipline & Courtesy' },
    { icon: faExclamationTriangle, text: 'Crimes Prevention' },
    { icon: faFireExtinguisher, text: 'Fire Hazard and Fire Fighting' },
    { icon: faHeartbeat, text: 'Personal Hygiene & Safety' }
  ];

  const advancedSkills = [
    { icon: faBroadcastTower, text: 'Security Radio Communications' },
    { icon: faHandshake, text: 'Values & Code of Ethics' },
    { icon: faHandRock, text: 'Baton Handling' },
    { icon: faSprayCan, text: 'Pepper Spray & Handcuff Use' },
    { icon: faBook, text: 'Standard Operating Procedures' },
    { icon: faUserShield, text: 'VIP Protection' },
    { icon: faFistRaised, text: 'Physical Self Defense' }
  ];

  const specializedTraining = [
    { icon: faDoorOpen, text: 'Access Control/Risk Management' },
    { icon: faUsersCog, text: 'Events Security Control' },
    { icon: faUserTie, text: 'Security Leadership' },
    { icon: faCar, text: 'Escort & Convoy Operations' },
    { icon: faEye, text: 'Surveillance Techniques' },
    { icon: faSearch, text: 'Crime Scene Observation' },
    { icon: faShieldAlt, text: 'Guard Duties' }
  ];

  const trainingProcess = [
    { icon: faClock, text: 'Three weeks of intensive training' },
    { icon: faClipboardCheck, text: 'Evaluation tests and background checks' },
    { icon: faUserCheck, text: 'Medical record verification' },
    { icon: faGraduationCap, text: 'Professional certification' },
    { icon: faSync, text: 'Monthly evaluations and refresher training' }
  ];

  const benefits = [
    { icon: faCheck, text: 'Official uniforms' },
    { icon: faCheck, text: 'Security equipment' },
    { icon: faCheck, text: 'Professional certification' }
  ];

  return (
    <div className="careers-page">
      {/* Page Header */}
      <header className="page-header" style={headerStyle}>
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h1>Join Our Team</h1>
              <p className="lead">Build Your Career in Professional Security Services</p>
            </Col>
          </Row>
        </Container>
      </header>

      {/* Recruitment Process */}
      <section className="recruitment-process py-5">
        <Container>
          <h2 className="text-center mb-5">TUNISCONS Recruitment Process and Training</h2>
          <Row>
            <Col lg={8} className="mb-4">
              <div className="process-card p-4 bg-white rounded shadow-sm">
                <p>Prior to training, an evaluation test involving confirmation of police criminal background checks and medical records are conducted. TUNISCONS guards are trained and exhibit professionalism at all times to protect lives and properties.</p>
                <h4 className="mt-4 mb-3">Recruitment Requirements:</h4>
                <ul className="list-unstyled">
                  {requirements.map((req, index) => (
                    <li className="mb-3" key={index}>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-primary me-2" />
                      {req}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col lg={4} className="mb-4">
              <div className="image-gallery">
                <img 
                  src={process.env.PUBLIC_URL + '/images/single-2.jpg'} 
                  alt="Security Training" 
                  className="img-fluid rounded mb-4" 
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Training Program */}
      <section className="training-program py-5 bg-light">
        <Container>
          <h3 className="text-center mb-5">Training Courses</h3>
          <Row className="g-4">
            <Col md={4}>
              <div className="course-card p-4 bg-white rounded shadow-sm h-100">
                <h4 className="mb-3">Basic Training</h4>
                <ul className="list-unstyled">
                  {basicTraining.map((item, index) => (
                    <li key={index}>
                      <FontAwesomeIcon icon={item.icon} className="me-2 text-primary" />
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <div className="course-card p-4 bg-white rounded shadow-sm h-100">
                <h4 className="mb-3">Advanced Skills</h4>
                <ul className="list-unstyled">
                  {advancedSkills.map((item, index) => (
                    <li key={index}>
                      <FontAwesomeIcon icon={item.icon} className="me-2 text-primary" />
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <div className="course-card p-4 bg-white rounded shadow-sm h-100">
                <h4 className="mb-3">Specialized Training</h4>
                <ul className="list-unstyled">
                  {specializedTraining.map((item, index) => (
                    <li key={index}>
                      <FontAwesomeIcon icon={item.icon} className="me-2 text-primary" />
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Training Process */}
      <section className="training-process py-5">
        <Container>
          <Row>
            <Col lg={6} className="mb-4">
              <div className="image-gallery">
                <img 
                  src={process.env.PUBLIC_URL + '/images/single-1.jpg'} 
                  alt="Security Training" 
                  className="img-fluid rounded mb-4" 
                />
              </div>
            </Col>
            <Col lg={6}>
              <div className="process-description p-4 bg-white rounded shadow-sm">
                <h3 className="mb-4">Training Process</h3>
                <p>Our comprehensive training program includes:</p>
                <ul className="list-unstyled">
                  {trainingProcess.map((item, index) => (
                    <li className="mb-3" key={index}>
                      <FontAwesomeIcon icon={item.icon} className="text-primary me-2" />
                      {item.text}
                    </li>
                  ))}
                </ul>
                <p className="mt-4">Successful candidates receive:</p>
                <ul className="list-unstyled">
                  {benefits.map((item, index) => (
                    <li className="mb-2" key={index}>
                      <FontAwesomeIcon icon={item.icon} className="text-primary me-2" />
                      {item.text}
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Careers;
