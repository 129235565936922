import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckCircle, 
  faBullseye, 
  faEye, 
  faShieldAlt, 
  faStar, 
  faHandshake, 
  faLightbulb,
  faUsers,
  faCogs,
  faHeadset
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const About = () => {
  const [aboutData, setAboutData] = useState({
    title: '',
    description: '',
    mission: '',
    vision: '',
    mainImage: { url: '', alt: '' },
    values: []
  });

  useEffect(() => {
    fetchAboutData();
  }, []);

  const fetchAboutData = async () => {
    try {
      // Local development URL
      // const response = await axios.get('http://localhost:4000/api/about');
      
      // Production URL
      const response = await axios.get('https://tunisconsbackend.onrender.com/api/about');
      if (response.data) {
        setAboutData(response.data);
      }
    } catch (err) {
      console.error('Failed to fetch about page data:', err);
    }
  };

  const headerStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/images/back-background.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };

  const ctaStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${process.env.PUBLIC_URL}/images/cta-bg.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };

  return (
    <div className="about-page">
      {/* Page Header */}
      <header className="page-header" style={headerStyle}>
        <Container>
          <Row>
            <Col xs={12} className="text-center text-white">
              <h1>{aboutData.title || 'About TUNISCONS'}</h1>
              <p className="lead">Your Trusted Partner in Security Solutions</p>
            </Col>
          </Row>
        </Container>
      </header>

      {/* Company Overview */}
      <section className="company-overview py-5">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="about-image">
                <div className="image-placeholder">
                  {aboutData.mainImage?.url ? (
                    <img 
                      // Local development URL
                      // src={`http://localhost:4000${aboutData.mainImage.url}`}
                      
                      // Production URL
                      src={`https://tunisconsbackend.onrender.com${aboutData.mainImage.url}`}
                      alt={aboutData.mainImage.alt} 
                      className="img-fluid"
                    />
                  ) : (
                    <img 
                      src={`${process.env.PUBLIC_URL}/images/badge.jpg`} 
                      alt="TUNISCONS Badge" 
                      className="img-fluid"
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <h2>Our Story</h2>
              {aboutData.description ? (
                <p>{aboutData.description}</p>
              ) : (
                <>
                  <p className="lead">A 100% Liberian-owned private security company established on July 1st, 2019.</p>
                  <p>THE UNIT SECURITY & CONSULTANCY SERVICES (TUNISCONS) INC. was established pursuant to the business Association Laws of Liberia. Located in the 72nd Community, opposite 72nd Market, Paynesville-City, Montserrado county Liberia, our company brings together experienced professionals from various security backgrounds.</p>
                  <p>Our team comprises international and trained ex-security personnel from prestigious organizations including:</p>
                  <ul className="list-unstyled">
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-primary me-2" />
                      Armed Forces of Liberia (AFL)
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-primary me-2" />
                      Executive Protective Service (EPS), formerly SSS
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} className="text-primary me-2" />
                      Liberian National Police (LNP)
                    </li>
                  </ul>
                  <p>Since our establishment, we’ve built an impressive track record of providing guard services to numerous Liberian Businesses, Public Offices, Private homes, and foreign embassies in Monrovia and throughout Liberia. Today, we proudly employ over 350 trained private security guards and maintain all necessary certifications and licenses through the Ministry of Justice, Republic of Liberia.</p>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>

      {/* Mission & Vision */}
      <section className="mission-vision py-5 bg-light">
        <Container>
          <Row className="g-4">
            <Col md={6}>
              <div className="p-4 h-100 bg-white rounded shadow-sm">
                <FontAwesomeIcon icon={faBullseye} className="text-primary mb-3" size="2x" />
                <h3 className="mb-4">Our Mission</h3>
                <p>{aboutData.mission || 'Loading...'}</p>
              </div>
            </Col>
            <Col md={6}>
              <div className="p-4 h-100 bg-white rounded shadow-sm">
                <FontAwesomeIcon icon={faEye} className="text-primary mb-3" size="2x" />
                <h3 className="mb-4">Our Vision</h3>
                <p>{aboutData.vision || 'Loading...'}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Values Section */}
      <section className="py-5">
        <Container>
          <h2 className="text-center mb-5">Our Values</h2>
          <Row className="g-4">
            {aboutData.values && aboutData.values.length > 0 ? (
              aboutData.values.map((value, index) => (
                <Col md={4} key={index}>
                  <div className="text-center p-4 bg-light rounded h-100">
                    <FontAwesomeIcon 
                      icon={value.icon ? value.icon : faCheckCircle} 
                      className="text-primary mb-3" 
                      size="2x" 
                    />
                    <h4>{value.title}</h4>
                    <p className="mb-0">{value.description}</p>
                  </div>
                </Col>
              ))
            ) : (
              <>
                <Col md={4}>
                  <div className="text-center p-4 bg-light rounded h-100">
                    <FontAwesomeIcon icon={faShieldAlt} className="text-primary mb-3" size="2x" />
                    <h4>Quality</h4>
                    <p className="mb-0">We are committed to delivering the highest quality in every project we undertake.</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="text-center p-4 bg-light rounded h-100">
                    <FontAwesomeIcon icon={faHandshake} className="text-primary mb-3" size="2x" />
                    <h4>Integrity</h4>
                    <p className="mb-0">We conduct our business with the highest standards of professional behavior and ethics.</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="text-center p-4 bg-light rounded h-100">
                    <FontAwesomeIcon icon={faUsers} className="text-primary mb-3" size="2x" />
                    <h4>Collaboration</h4>
                    <p className="mb-0">We believe in the power of teamwork and fostering strong partnerships.</p>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </section>

      {/* CTA Section */}
      <section className="text-white py-5" style={ctaStyle}>
        <Container className="text-center py-4">
          <h2 className="mb-4">Ready to Start Your Project?</h2>
          <p className="lead mb-4">Contact us today to discuss how we can help bring your vision to life.</p>
          <button className="btn btn-primary btn-lg">Contact Us</button>
        </Container>
      </section>
    </div>
  );
};

export default About;
