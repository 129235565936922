import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-hot-toast';

const ContactConfig = () => {
  const [contact, setContact] = useState({
    location: '',
    phoneNumbers: '',
    email: ''
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchContact();
  }, []);

  const fetchContact = async () => {
    try {
      // Production URL
      const response = await axios.get('https://tunisconsbackend.onrender.com/api/contact');
      // Local development URL
      // const response = await axios.get('http://localhost:5000/api/contact');
      if (response.data) {
        setContact(response.data);
      }
    } catch (err) {
      console.error('Error fetching contact info:', err);
      toast.error('Failed to load contact information');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Production URL
      await axios.post('https://tunisconsbackend.onrender.com/api/contact', contact);
      // Local development URL
      // await axios.post('http://localhost:5000/api/contact', contact);
      toast.success('Contact information updated successfully');
    } catch (err) {
      console.error('Error updating contact info:', err);
      toast.error('Failed to update contact information');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Card>
      <Card.Body>
        <h4 className="mb-4">Contact Information</h4>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Location</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="location"
              value={contact.location}
              onChange={handleChange}
              placeholder="Enter your business location"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone Numbers</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="phoneNumbers"
              value={contact.phoneNumbers}
              onChange={handleChange}
              placeholder="Enter phone numbers (one per line)"
              required
            />
            <Form.Text className="text-muted">
              Enter multiple phone numbers, one per line
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={contact.email}
              onChange={handleChange}
              placeholder="Enter contact email"
              required
            />
          </Form.Group>

          <Button 
            type="submit" 
            variant="primary"
            disabled={loading}
          >
            {loading ? 'Updating...' : 'Update Contact Information'}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ContactConfig;
