import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ClientsSection = () => {
  const clients = [
    {
      name: 'Sethi Ferro Fabric',
      location: 'Steel Rod Factory, Garnerville, Liberia'
    },
    {
      name: 'G-5 Breweries',
      location: 'Formerly Liberia Coca Cola Bottling Company LCCBC, Paynesville City, Liberia'
    },
    {
      name: 'GBK Motors',
      location: 'Sayon Town, Bushord Island'
    },
    {
      name: 'Abi Joudi Group of Companies',
      location: 'Sayon Town, Bushord Island'
    },
    {
      name: 'Fresh Frozen Food',
      location: 'Central Monrovia, Omega, Gompa City'
    },
    {
      name: 'TRH Incorporated',
      location: 'Topoe Village'
    },
    {
      name: 'Mr. Husin Ibrahim',
      location: 'Private Client'
    },
    {
      name: 'Free Port of Monrovia',
      location: 'Port Facility'
    },
    {
      name: 'ABJ Ware Houses',
      location: 'Storage Facility'
    },
    {
      name: 'A&G Enterprise',
      location: 'Garnerville, Liberia'
    },
    {
      name: 'Marshall City',
      location: 'Municipal Client'
    },
    {
      name: 'Monrovia City',
      location: 'Multiple Private Facilities'
    },
    {
      name: 'Browerville City',
      location: 'Municipal Client'
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 3;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex + itemsPerPage >= clients.length ? 0 : prevIndex + itemsPerPage
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex - itemsPerPage < 0 ? Math.max(0, clients.length - itemsPerPage) : prevIndex - itemsPerPage
    );
  };

  const visibleClients = clients.slice(currentIndex, currentIndex + itemsPerPage);

  return (
    <section className="clients-section py-5">
      <Container>
        <div className="section-header text-center mb-5">
          <h2>Our Trusted Clients</h2>
          <p className="lead">Serving Leading Organizations Across Liberia</p>
        </div>
        <div className="clients-carousel">
          <Row className="client-list">
            {visibleClients.map((client, index) => (
              <Col md={4} key={index}>
                <div className="client-item">
                  <h3>{client.name}</h3>
                  <p>{client.location}</p>
                </div>
              </Col>
            ))}
          </Row>
          <div className="carousel-controls">
            <button className="prev-btn" onClick={prevSlide} aria-label="Previous">
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </button>
            <button className="next-btn" onClick={nextSlide} aria-label="Next">
              <FontAwesomeIcon icon={faChevronRight} size="lg" />
            </button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ClientsSection;
