import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhone,
  faEnvelope,
  faShieldAlt,
  faMoneyBillWave,
  faUsers,
  faVoteYea,
  faCar
} from '@fortawesome/free-solid-svg-icons';

const Team = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    try {
      // Local development URL
      // const response = await axios.get('http://localhost:4000/api/team');
      
      // Production URL
      const response = await axios.get('https://tunisconsbackend.onrender.com/api/team');
      setTeamMembers(response.data);
    } catch (error) {
      console.error('Error fetching team members:', error);
    }
  };

  const headerStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/images/back-background.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };

  const stats = [
    { number: '350+', text: 'Security Personnel' },
    { number: '13+', text: 'Major Clients' },
    { number: '5+', text: 'Years Experience' },
    { number: '24/7', text: 'Service Available' }
  ];

  const setServices = [
    { icon: faShieldAlt, text: 'VIP Protection and Bodyguard Services' },
    { icon: faMoneyBillWave, text: 'Cash Transport Services' },
    { icon: faUsers, text: 'Event/Occasion Security' },
    { icon: faVoteYea, text: 'Elections Process Security' },
    { icon: faCar, text: 'Security Transport & Close Protection Services' },
    { icon: faShieldAlt, text: 'Other specialized security services' }
  ];

  return (
    <div className="team-page">
      {/* Page Header */}
      <header className="page-header" style={headerStyle}>
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h1>Meet Our Team</h1>
              <p className="lead">Dedicated Professionals Committed to Your Security</p>
            </Col>
          </Row>
        </Container>
      </header>

      {/* Leadership Team */}
      <section className="leadership-team py-5">
        <Container>
          <h2 className="text-center mb-5">Leadership Team</h2>
          <Row className="g-4">
            {[...teamMembers].reverse().map((member) => (
              <Col md={4} key={member._id}>
                <div className="team-card">
                  <div className="team-image">
                    {member.image ? (
                      <img
                        // Local development URL
                        // src={`http://localhost:4000${member.image}`}
                        
                        // Production URL
                        src={`https://tunisconsbackend.onrender.com${member.image}`}
                        alt={member.name}
                      />
                    ) : (
                      <img
                        src={process.env.PUBLIC_URL + '/images/male.png'}
                        alt={member.name}
                      />
                    )}
                  </div>
                  <div className="team-info">
                    <h3>{member.name}</h3>
                    <p className="position">{member.position}</p>
                    <div className="contact-info">
                      {member.phone && (
                        <p>
                          <FontAwesomeIcon icon={faPhone} className="me-2" />
                          {member.phone}
                        </p>
                      )}
                      {member.email && (
                        <p>
                          <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                          {member.email}
                        </p>
                      )}
                    </div>
                    {member.bio && <p className="bio">{member.bio}</p>}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Strategic Escort Team */}
      <section className="strategic-team py-5 bg-light">
        <Container>
          <h2 className="text-center mb-3">TUNISCONS Strategy Escort Team (SET)</h2>
          <Row className="justify-content-center">
            <Col lg={10} className="position-relative">
              <div className="team-image mb-4 set-team-image">
                <img
                  src={process.env.PUBLIC_URL + '/images/team-1.jpg'}
                  alt="Strategy Escort Team"
                  className="img-fluid rounded shadow"
                />
              </div>
              <div className="team-description p-4 bg-white rounded shadow-sm">
                <p className="mb-0">
                  The Strategic Escort Team (SET) is an Auxiliary of The Unit Security and Consultancy Services (TUNISCONS) Inc.
                  They are a specialized group of individuals trained in specific tactics and functions in the field of:
                </p>
                <ul className="list-unstyled mt-3">
                  {setServices.map((service, index) => (
                    <li key={index}>
                      <FontAwesomeIcon icon={service.icon} className="me-2 text-primary" />
                      {service.text}
                    </li>
                  ))}
                </ul>
                <p className="mt-3 mb-0">
                  Our trained veterans bring great expertise to this specialized field of our operations.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Team Stats */}
      <section className="team-stats py-5">
        <Container>
          <Row className="text-center g-4">
            {stats.map((stat, index) => (
              <Col md={3} key={index}>
                <div className="stat-item">
                  <h3>{stat.number}</h3>
                  <p>{stat.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <style jsx>{`
        .team-page {
          background-color: #fff;
        }
        .page-header {
          padding: 150px 0 80px;
          color: #fff;
          text-align: center;
          margin-bottom: 0;
        }
        .page-header h1 {
          font-size: 3rem;
          margin-bottom: 1rem;
        }
        .team-card {
          background: #fff;
          border-radius: 10px;
          box-shadow: 0 0 20px rgba(0,0,0,0.1);
          overflow: hidden;
          height: 100%;
        }
        .team-image {
          position: relative;
          overflow: hidden;
        }
        .team-image img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }
        .team-info {
          padding: 20px;
        }
        .team-info h3 {
          color: #333;
          margin-bottom: 10px;
          font-size: 1.5rem;
        }
        .position {
          color: #666;
          font-size: 1.1rem;
          margin-bottom: 15px;
        }
        .contact-info {
          margin-bottom: 15px;
        }
        .contact-info p {
          margin-bottom: 5px;
          color: #666;
        }
        .bio {
          color: #666;
          font-size: 0.9rem;
          line-height: 1.6;
        }
        .set-team-image img {
          width: 100%;
          height: auto;
        }
        .team-description {
          font-size: 1rem;
          line-height: 1.6;
        }
        .team-description ul li {
          margin-bottom: 10px;
        }
        .stat-item {
          padding: 20px;
          background: #fff;
          border-radius: 10px;
          box-shadow: 0 0 15px rgba(0,0,0,0.1);
        }
        .stat-item h3 {
          color: #007bff;
          font-size: 2.5rem;
          margin-bottom: 10px;
        }
        .stat-item p {
          color: #666;
          margin: 0;
        }
      `}</style>
    </div>
  );
};

export default Team;
