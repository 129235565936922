import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Toaster } from 'react-hot-toast';

// Import Admin Components
import AdminSidebar from '../components/admin/AdminSidebar';
import AboutConfig from '../components/admin/AboutConfig';
import ServiceConfig from '../components/admin/ServiceConfig';
import TeamConfig from '../components/admin/TeamConfig';
import GalleryConfig from '../components/admin/GalleryConfig';
import ContactConfig from '../components/admin/ContactConfig';

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('team');
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is logged in
    const token = localStorage.getItem('adminToken');
    if (!token) {
      navigate('/admin/login');
    }
  }, [navigate]);

  const handleLogout = () => {
    // Clear admin data from localStorage
    localStorage.removeItem('adminToken');
    localStorage.removeItem('adminUsername');
    // Redirect to login page
    navigate('/admin/login');
  };

  const renderPageConfig = () => {
    switch(activeTab) {
      case 'about':
        return <AboutConfig />;
      case 'services':
        return <ServiceConfig />;
      case 'team':
        return <TeamConfig />;
      case 'gallery':
        return <GalleryConfig />;
      case 'careers':
        return <Card><Card.Body>Careers page configuration coming soon...</Card.Body></Card>;
      case 'contact':
        return <ContactConfig />;
      default:
        return (
          <Card>
            <Card.Body className="text-center">
              <h4>Welcome to TUNISCONS Admin Dashboard</h4>
              <p className="text-muted">Select a page from the menu to start editing</p>
            </Card.Body>
          </Card>
        );
    }
  };
  
  return (
    <div className="admin-dashboard d-flex" style={{ minHeight: '100vh' }}>
      <Toaster position="top-right" />
      
      {/* Sidebar */}
      <AdminSidebar 
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        sidebarOpen={sidebarOpen}
      />
      
      {/* Main Content */}
      <div className="flex-grow-1 bg-light">
        <div className="p-3">
          <Button
            variant="link"
            className="mb-3 text-dark"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
          
          <Container fluid>
            <Row>
              <Col md={12} className="py-4">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h2>Admin Dashboard</h2>
                  <Button variant="outline-danger" onClick={handleLogout}>
                    Logout
                  </Button>
                </div>
                {renderPageConfig()}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;