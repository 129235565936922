import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav, Container } from 'react-bootstrap';
import logo from '../assets/images/logo.png';

const Navbar = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <BootstrapNavbar expand="lg" variant="dark" fixed="top">
      <Container className="position-relative">
        <div className="logo-section">
          <BootstrapNavbar.Brand as={Link} to="/">
            <img
              src={logo}
              alt="TUNISCONS Logo"
              style={{ height: '30px', width: 'auto', marginRight: '10px', verticalAlign: 'middle' }}
            />
            TUNISCONS
          </BootstrapNavbar.Brand>
        </div>
        <BootstrapNavbar.Toggle aria-controls="navbarNav" />
        <BootstrapNavbar.Collapse id="navbarNav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/" active={currentPath === "/" || currentPath === "/home"}>Home</Nav.Link>
            <Nav.Link as={Link} to="/about" active={currentPath === "/about"}>About Us</Nav.Link>
            <Nav.Link as={Link} to="/services" active={currentPath === "/services"}>Services</Nav.Link>
            <Nav.Link as={Link} to="/team" active={currentPath === "/team"}>Our Team</Nav.Link>
            <Nav.Link as={Link} to="/gallery" active={currentPath === "/gallery"}>Gallery</Nav.Link>
            <Nav.Link as={Link} to="/careers" active={currentPath === "/careers"}>Careers</Nav.Link>
            <Nav.Link as={Link} to="/contact" active={currentPath === "/contact"}>Contact</Nav.Link>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;
