import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';

const AboutConfig = () => {
  const [aboutData, setAboutData] = useState({
    title: '',
    description: '',
    mission: '',
    vision: '',
    values: [],
    mainImage: {
      url: '',
      alt: ''
    }
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAboutData();
  }, []);

  const fetchAboutData = async () => {
    try {
      // Production URL
      const response = await axios.get('https://tunisconsbackend.onrender.com/api/about');
      // Local development URL
      // const response = await axios.get('http://localhost:5000/api/about');
      if (response.data) {
        setAboutData(response.data);
        if (response.data.mainImage?.url) {
          // Production URL
          setImagePreview(`https://tunisconsbackend.onrender.com${response.data.mainImage.url}`);
          // Local development URL
          // setImagePreview(`http://localhost:5000${response.data.mainImage.url}`);
        }
      }
    } catch (err) {
      console.error('Error fetching about data:', err);
      toast.error('Failed to load about data');
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // First update the main content
      // Production URL
      const response = await axios.put('https://tunisconsbackend.onrender.com/api/about', aboutData);
      // Local development URL
      // const response = await axios.put('http://localhost:5000/api/about', aboutData);

      // If there's a new image, upload it
      if (selectedImage) {
        const formData = new FormData();
        formData.append('image', selectedImage);
        formData.append('alt', aboutData.mainImage?.alt || 'About page image');
        
        // Production URL
        await axios.post('https://tunisconsbackend.onrender.com/api/about/image', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        // Local development URL
        // await axios.post('http://localhost:5000/api/about/image', formData, {
        //   headers: { 'Content-Type': 'multipart/form-data' }
        // });
      }

      toast.success('About page updated successfully');
      fetchAboutData(); // Refresh data after update
    } catch (err) {
      console.error('Error updating about data:', err);
      setError('Failed to update about page');
      toast.error('Failed to update about page');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAboutData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddValue = () => {
    setAboutData(prev => ({
      ...prev,
      values: [...prev.values, { icon: '', title: '', description: '' }]
    }));
  };

  const handleRemoveValue = (index) => {
    setAboutData(prev => ({
      ...prev,
      values: prev.values.filter((_, i) => i !== index)
    }));
  };

  const handleValueChange = (index, field, value) => {
    setAboutData(prev => ({
      ...prev,
      values: prev.values.map((item, i) => 
        i === index ? { ...item, [field]: value } : item
      )
    }));
  };

  return (
    <Card>
      <Card.Body>
        <h4 className="mb-4">About Page Configuration</h4>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={8}>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={aboutData.title}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="description"
                  value={aboutData.description}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Mission</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="mission"
                  value={aboutData.mission}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Vision</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="vision"
                  value={aboutData.vision}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Main Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleImageChange}
                  accept="image/*"
                />
                {imagePreview && (
                  <div className="mt-2">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="img-fluid rounded"
                    />
                  </div>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Image Alt Text</Form.Label>
                <Form.Control
                  type="text"
                  name="mainImage.alt"
                  value={aboutData.mainImage?.alt || ''}
                  onChange={(e) => setAboutData(prev => ({
                    ...prev,
                    mainImage: { ...prev.mainImage, alt: e.target.value }
                  }))}
                />
              </Form.Group>
            </Col>
          </Row>

          <h5 className="mt-4 mb-3">Company Values</h5>
          {aboutData.values.map((value, index) => (
            <Card key={index} className="mb-3">
              <Card.Body>
                <Row>
                  <Col md={11}>
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3">
                          <Form.Label>Icon</Form.Label>
                          <Form.Control
                            type="text"
                            value={value.icon}
                            onChange={(e) => handleValueChange(index, 'icon', e.target.value)}
                            placeholder="e.g., faShieldAlt"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={8}>
                        <Form.Group className="mb-3">
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            type="text"
                            value={value.title}
                            onChange={(e) => handleValueChange(index, 'title', e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        value={value.description}
                        onChange={(e) => handleValueChange(index, 'description', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={1} className="d-flex align-items-center justify-content-center">
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => handleRemoveValue(index)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          ))}

          <Button
            variant="outline-primary"
            type="button"
            className="mb-4"
            onClick={handleAddValue}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Add Value
          </Button>

          <div>
            <Button 
              type="submit" 
              variant="primary" 
              disabled={loading}
            >
              {loading ? 'Updating...' : 'Update About Page'}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default AboutConfig;
