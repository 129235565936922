import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Form, Button, Alert, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-hot-toast';
import * as solidIcons from '@fortawesome/free-solid-svg-icons';

const ServiceConfig = () => {
  const [services, setServices] = useState([]);
  const [editingService, setEditingService] = useState(null);
  const [newService, setNewService] = useState({
    name: '',
    description: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/services');
      setServices(response.data);
    } catch (err) {
      console.error('Error fetching services:', err);
      toast.error('Failed to load services');
    }
  };

  const handleAddService = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await axios.post('http://localhost:5000/api/services', newService);
      setServices([...services, response.data]);
      setNewService({ name: '', description: '' });
      toast.success('Service added successfully');
    } catch (err) {
      console.error('Error adding service:', err);
      setError('Failed to add service');
      toast.error('Failed to add service');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateService = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      // Production URL
      const response = await axios.put(
        `https://tunisconsbackend.onrender.com/api/services/${editingService._id}`,
        editingService
      );
      // Local development URL
      // const response = await axios.put(
      //   `http://localhost:5000/api/services/${editingService._id}`,
      //   editingService
      // );
      setServices(services.map(service => 
        service._id === editingService._id ? response.data : service
      ));
      setEditingService(null);
      toast.success('Service updated successfully');
    } catch (err) {
      console.error('Error updating service:', err);
      setError('Failed to update service');
      toast.error('Failed to update service');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteService = async (serviceId) => {
    if (!window.confirm('Are you sure you want to delete this service?')) return;

    try {
      // Production URL
      await axios.delete(`https://tunisconsbackend.onrender.com/api/services/${serviceId}`);
      // Local development URL
      // await axios.delete(`http://localhost:5000/api/services/${serviceId}`);
      setServices(services.filter(service => service._id !== serviceId));
      toast.success('Service deleted successfully');
    } catch (err) {
      console.error('Error deleting service:', err);
      toast.error('Failed to delete service');
    }
  };

  const ServiceForm = ({ service, onSubmit, buttonText }) => (
    <Form onSubmit={onSubmit} className="mb-4">
      <Form.Group className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          value={service.name}
          onChange={(e) => service === newService
            ? setNewService({ ...newService, name: e.target.value })
            : setEditingService({ ...editingService, name: e.target.value })
          }
          required
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={service.description}
          onChange={(e) => service === newService
            ? setNewService({ ...newService, description: e.target.value })
            : setEditingService({ ...editingService, description: e.target.value })
          }
          required
        />
      </Form.Group>

      <Button type="submit" variant="primary" disabled={loading}>
        {loading ? 'Saving...' : buttonText}
      </Button>
      {editingService && (
        <Button
          variant="secondary"
          className="ms-2"
          onClick={() => setEditingService(null)}
        >
          Cancel
        </Button>
      )}
    </Form>
  );

  return (
    <Card>
      <Card.Body>
        <h4 className="mb-4">Services Configuration</h4>
        {error && <Alert variant="danger">{error}</Alert>}

        {!editingService && (
          <>
            <h5>Add New Service</h5>
            <ServiceForm
              service={newService}
              onSubmit={handleAddService}
              buttonText="Add Service"
            />
          </>
        )}

        {editingService && (
          <>
            <h5>Edit Service</h5>
            <ServiceForm
              service={editingService}
              onSubmit={handleUpdateService}
              buttonText="Update Service"
            />
          </>
        )}

        <h5 className="mt-4">Existing Services</h5>
        <ListGroup>
          {services.map(service => (
            <ListGroup.Item
              key={service._id}
              className="d-flex justify-content-between align-items-center"
            >
              <div>
                <FontAwesomeIcon 
                  icon={solidIcons[service.icon] || solidIcons.faWrench} 
                  className="me-2" 
                />
                <strong>{service.name}</strong>
                <p className="mb-0 text-muted">{service.description}</p>
              </div>
              <div>
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="me-2"
                  onClick={() => setEditingService(service)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => handleDeleteService(service._id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default ServiceConfig;
