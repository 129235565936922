import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-hot-toast';

const GalleryConfig = () => {
  const [images, setImages] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [caption, setCaption] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [editingCaptions, setEditingCaptions] = useState({});

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      // Local development URL
      // const response = await axios.get('http://localhost:4000/api/gallery');
      
      // Production URL
      const response = await axios.get('https://tunisconsbackend.onrender.com/api/gallery');
      console.log('Fetched images:', response.data);
      setImages(response.data);
    } catch (err) {
      console.error('Error fetching gallery images:', err);
      toast.error('Failed to load gallery images');
    }
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);

    // Create previews
    const newPreviews = files.map(file => URL.createObjectURL(file));
    setPreviews(newPreviews);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append('image', file);
        if (caption) {
          formData.append('caption', caption);
        }
        
        // Local development URL
        // await axios.post('http://localhost:4000/api/gallery', formData, {
        // Production URL
        await axios.post('https://tunisconsbackend.onrender.com/api/gallery', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }

      // Clear selection and refresh images
      setSelectedFiles([]);
      setPreviews([]);
      setCaption('');
      await fetchImages();
      toast.success('Images uploaded successfully');
    } catch (err) {
      console.error('Error uploading images:', err);
      setError('Failed to upload images');
      toast.error('Failed to upload images');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (imageId) => {
    if (!window.confirm('Are you sure you want to delete this image?')) return;

    try {
      // Local development URL
      // await axios.delete(`http://localhost:4000/api/gallery/${imageId}`);
      
      // Production URL
      await axios.delete(`https://tunisconsbackend.onrender.com/api/gallery/${imageId}`);
      await fetchImages();
      toast.success('Image deleted successfully');
    } catch (err) {
      console.error('Error deleting image:', err);
      toast.error('Failed to delete image');
    }
  };

  const handleCaptionChange = (imageId, newCaption) => {
    setEditingCaptions(prev => ({
      ...prev,
      [imageId]: newCaption
    }));
  };

  const handleUpdateCaption = async (imageId, newCaption) => {
    if (newCaption === undefined || newCaption === '') {
      toast.error('Please enter a caption');
      return;
    }

    try {
      console.log('Updating caption for image:', imageId);
      console.log('New caption:', newCaption);
      
      // Local development URL
      // const { data: updatedImage } = await axios.patch(
      //   `http://localhost:4000/api/gallery/${imageId}`, 
      //   { caption: newCaption }
      // );
      
      // Production URL
      const { data: updatedImage } = await axios.patch(
        `https://tunisconsbackend.onrender.com/api/gallery/${imageId}`, 
        { caption: newCaption }
      );
      
      console.log('Server response:', updatedImage);

      setImages(prevImages => {
        const newImages = prevImages.map(img => 
          img._id === imageId ? { ...img, caption: newCaption } : img
        );
        console.log('Updated images state:', newImages);
        return newImages;
      });
      
      toast.success('Caption updated successfully');
    } catch (err) {
      console.error('Error updating caption:', err);
      toast.error(err.response?.data?.error || 'Failed to update caption');
    }
  };

  return (
    <Card>
      <Card.Body>
        <h4 className="mb-4">Gallery Management</h4>
        {error && <Alert variant="danger">{error}</Alert>}

        <Form onSubmit={handleUpload} className="mb-4">
          <Form.Group className="mb-3">
            <Form.Label>Upload Images</Form.Label>
            <Form.Control
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileSelect}
              required
            />
            <Form.Text className="text-muted">
              You can select multiple images at once
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Caption (optional)</Form.Label>
            <Form.Control
              type="text"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              placeholder="Enter a caption for the image(s)"
            />
            <Form.Text className="text-muted">
              This caption will be applied to all selected images
            </Form.Text>
          </Form.Group>

          {previews.length > 0 && (
            <div className="mb-3">
              <h6>Selected Images Preview:</h6>
              <Row className="g-3">
                {previews.map((preview, idx) => (
                  <Col key={idx} xs={6} md={4} lg={3}>
                    <img
                      src={preview}
                      alt={`Preview ${idx + 1}`}
                      className="img-fluid rounded"
                      style={{ aspectRatio: '1/1', objectFit: 'cover' }}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          )}

          <Button 
            type="submit" 
            variant="primary" 
            disabled={loading || selectedFiles.length === 0}
          >
            <FontAwesomeIcon icon={faUpload} className="me-2" />
            {loading ? 'Uploading...' : 'Upload Images'}
          </Button>
        </Form>

        <h5>Gallery Images</h5>
        <Row className="g-3">
          {images.map((image) => (
            <Col key={image._id} xs={12} md={6} lg={4}>
              <Card>
                <Card.Img
                  variant="top"
                  src={`https://tunisconsbackend.onrender.com${image.image}`}
                  alt={image.caption || 'Gallery image'}
                  style={{ aspectRatio: '1/1', objectFit: 'cover' }}
                />
                <Card.Body>
                  <div className="caption-section mb-3">
                    {image.caption ? (
                      <div className="current-caption p-2 bg-light rounded">
                        <strong>Caption:</strong> {image.caption}
                      </div>
                    ) : (
                      <div className="text-muted small">No caption set</div>
                    )}
                  </div>
                  <Form onSubmit={(e) => {
                    e.preventDefault();
                    const input = e.target.elements.caption;
                    handleUpdateCaption(image._id, input.value);
                    input.value = '';
                  }}>
                    <Form.Control
                      name="caption"
                      type="text"
                      placeholder="Add or edit caption"
                      className="mb-2"
                    />
                    <div className="d-flex justify-content-between">
                      <Button
                        type="submit"
                        variant="primary"
                        size="sm"
                      >
                        Update Caption
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(image._id)}
                      >
                        <FontAwesomeIcon icon={faTrash} className="me-1" /> Delete
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GalleryConfig;
