import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Form, Button, Alert, ListGroup, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-hot-toast';

// Separate EditForm component
const EditForm = ({ member, onSubmit, onCancel, loading }) => {
  const [formData, setFormData] = useState({ ...member });
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const submitData = new FormData();
    
    // Add all form fields
    Object.keys(formData).forEach(key => {
      if (key !== '_id' && key !== '__v') {
        submitData.append(key, formData[key] || '');
      }
    });

    // Add image if selected
    if (imageFile) {
      submitData.append('image', imageFile);
    }

    onSubmit(submitData);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name || ''}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Position</Form.Label>
            <Form.Control
              type="text"
              name="position"
              value={formData.position || ''}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="tel"
              name="phone"
              value={formData.phone || ''}
              onChange={handleChange}
            />
          </Form.Group>
        </Col>

        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Bio</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="bio"
              value={formData.bio || ''}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Profile Image</Form.Label>
            <Form.Control
              type="file"
              onChange={handleImageChange}
              accept="image/*"
            />
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                style={{ maxWidth: '200px', marginTop: '10px' }}
              />
            )}
          </Form.Group>
        </Col>
      </Row>

      <div className="d-flex gap-2">
        <Button type="submit" variant="primary" disabled={loading}>
          {loading ? 'Saving...' : 'Save Changes'}
        </Button>
        <Button type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Form>
  );
};

const TeamConfig = () => {
  const [members, setMembers] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const emptyMember = {
    name: '',
    position: '',
    phone: '',
    email: '',
    bio: ''
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    try {
      // Local development URL
      // const response = await axios.get('http://localhost:4000/api/team');
      
      // Production URL
      const response = await axios.get('https://tunisconsbackend.onrender.com/api/team');
      setMembers(response.data);
    } catch (err) {
      console.error('Error fetching team members:', err);
      toast.error('Failed to load team members');
    }
  };

  const handleAdd = async (formData) => {
    setLoading(true);
    setError('');

    try {
      // Local development URL
      // const response = await axios.post(
      //   'http://localhost:4000/api/team',
        
      // Production URL
      const response = await axios.post(
        'https://tunisconsbackend.onrender.com/api/team',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      setMembers(prev => [...prev, response.data]);
      setShowAddForm(false);
      toast.success('Team member added successfully');
    } catch (err) {
      console.error('Error adding team member:', err);
      setError('Failed to add team member');
      toast.error('Failed to add team member');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (formData, id) => {
    setLoading(true);
    setError('');

    try {
      // Local development URL
      // const response = await axios.put(
      //   `http://localhost:4000/api/team/${id}`,
        
      // Production URL
      const response = await axios.put(
        `https://tunisconsbackend.onrender.com/api/team/${id}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      setMembers(prevMembers => 
        prevMembers.map(member => 
          member._id === id ? response.data : member
        )
      );

      setEditingId(null);
      toast.success('Team member updated successfully');
    } catch (err) {
      console.error('Error updating team member:', err);
      setError('Failed to update team member');
      toast.error('Failed to update team member');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this team member?')) return;

    try {
      await axios.delete(`https://tunisconsbackend.onrender.com/api/team/${id}`);
      setMembers(members.filter(member => member._id !== id));
      toast.success('Team member deleted successfully');
    } catch (err) {
      console.error('Error deleting team member:', err);
      toast.error('Failed to delete team member');
    }
  };

  return (
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>Team Members</h3>
          {!showAddForm && (
            <Button 
              variant="primary" 
              onClick={() => setShowAddForm(true)}
            >
              <FontAwesomeIcon icon={faPlus} /> Add New Member
            </Button>
          )}
        </div>
        
        {error && <Alert variant="danger">{error}</Alert>}
        
        {showAddForm && (
          <Card className="mb-4">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0">Add New Team Member</h5>
                <Button 
                  variant="outline-secondary" 
                  size="sm"
                  onClick={() => setShowAddForm(false)}
                >
                  Cancel
                </Button>
              </div>
              <EditForm
                member={emptyMember}
                onSubmit={handleAdd}
                onCancel={() => setShowAddForm(false)}
                loading={loading}
              />
            </Card.Body>
          </Card>
        )}
        
        <ListGroup className="mb-4">
          {members.map(member => (
            <ListGroup.Item key={member._id} className="d-flex justify-content-between align-items-center">
              {editingId === member._id ? (
                <EditForm
                  member={member}
                  onSubmit={(formData) => handleUpdate(formData, member._id)}
                  onCancel={() => setEditingId(null)}
                  loading={loading}
                />
              ) : (
                <>
                  <div>
                    <h5 className="mb-1">{member.name}</h5>
                    <p className="mb-0 text-muted">{member.position}</p>
                  </div>
                  <div>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="me-2"
                      onClick={() => setEditingId(member._id)}
                    >
                      <FontAwesomeIcon icon={faEdit} /> Edit
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={() => handleDelete(member._id)}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Delete
                    </Button>
                  </div>
                </>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default TeamConfig;
