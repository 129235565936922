import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarSide, faUserShield, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

const ServicesPreview = () => {
  const services = [
    {
      icon: faCarSide,
      title: 'Security Transport',
      description: 'Comprehensive transport services for embassies, organizations, and individuals, including airport transfers and city escorts.',
      link: '/services#security-transport'
    },
    {
      icon: faUserShield,
      title: 'VIP Protection',
      description: 'Elite close protection operations with strategic planning and professional security teams for VIP safety.',
      link: '/services#vip-protection'
    },
    {
      icon: faCalendarCheck,
      title: 'Events Security',
      description: 'Comprehensive event protection with trained personnel, advanced equipment, and coordinated security planning.',
      link: '/services#events-security'
    }
  ];

  return (
    <section className="services-preview py-5">
      <Container>
        <div className="section-header text-center mb-5">
          <h2>Our Services</h2>
          <p className="lead">Professional Security Solutions for Your Complete Protection</p>
        </div>
        <Row className="g-4 justify-content-center">
          {services.map((service, index) => (
            <Col md={4} key={index}>
              <div className="service-card">
                <div className="service-icon">
                  <FontAwesomeIcon icon={service.icon} />
                </div>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <Link to={service.link} className="btn btn-outline-primary">Learn More</Link>
              </div>
            </Col>
          ))}
        </Row>
        <div className="text-center mt-5">
          <Link to="/services" className="btn btn-primary btn-lg">View All Services</Link>
        </div>
      </Container>
    </section>
  );
};

export default ServicesPreview;
