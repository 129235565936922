import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faShieldAlt, 
  faUserShield, 
  faUsers, 
  faBuilding, 
  faClock, 
  faCheckCircle,
  faIndustry,
  faLandmark,
  faHome
} from '@fortawesome/free-solid-svg-icons';

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Default services as fallback
  const defaultServices = [
    {
      name: "Armed Security",
      description: "Professional armed security services for high-risk facilities and valuable assets.",
      icon: faShieldAlt
    },
    {
      name: "Unarmed Security",
      description: "Trained security personnel for access control, surveillance, and general security.",
      icon: faUserShield
    },
    {
      name: "Event Security",
      description: "Comprehensive security coverage for events, conferences, and gatherings.",
      icon: faUsers
    },
    {
      name: "Corporate Security",
      description: "Tailored security solutions for corporate environments and office buildings.",
      icon: faBuilding
    },
    {
      name: "24/7 Monitoring",
      description: "Round-the-clock security monitoring and rapid response services.",
      icon: faClock
    }
  ];

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      // Production URL
      const response = await axios.get('https://tunisconsbackend.onrender.com/api/services');
      // Local development URL
      // const response = await axios.get('http://localhost:5000/api/services');
      setServices(response.data);
      setLoading(false);
    } catch (err) {
      console.error('Failed to fetch services:', err);
      setError(err);
      setLoading(false);
    }
  };

  // Function to get an icon for a service (cycles through available icons)
  const getServiceIcon = (index) => {
    const icons = [faShieldAlt, faUserShield, faUsers, faBuilding, faClock, faCheckCircle];
    return icons[index % icons.length];
  };

  return (
    <div className="services-page">
      {/* Header Section */}
      <div 
        className="page-header text-white py-5 mb-5"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/images/back-background.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Container>
          <Row className="justify-content-center text-center">
            <Col md={8}>
              <h1 className="display-4">Our Services</h1>
              <p className="lead">
                Professional security solutions tailored to your needs
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Services Section */}
      <Container className="py-5">
        <Row>
          {loading ? (
            <Col xs={12} className="text-center">
              <p>Loading services...</p>
            </Col>
          ) : error || !services.length ? (
            // Show default services if there's an error or no services
            defaultServices.map((service, index) => (
              <Col md={6} lg={4} key={index} className="mb-4">
                <Card className="h-100 shadow-sm hover-card">
                  <Card.Body className="text-center">
                    <div className="service-icon">
                      <FontAwesomeIcon icon={service.icon} className="text-primary" size="2x" />
                    </div>
                    <Card.Title>{service.name}</Card.Title>
                    <Card.Text className="text-muted">
                      {service.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            // Show CMS services
            services.map((service, index) => (
              <Col md={6} lg={4} key={service._id} className="mb-4">
                <Card className="h-100 shadow-sm hover-card">
                  <Card.Body className="text-center">
                    <div className="service-icon">
                      <FontAwesomeIcon icon={getServiceIcon(index)} className="text-primary" size="2x" />
                    </div>
                    <Card.Title>{service.name}</Card.Title>
                    <Card.Text className="text-muted">
                      {service.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>
      </Container>

      {/* Client Types */}
      <section className="client-types py-5 bg-light">
        <Container>
          <h2 className="text-center mb-5">Who We Serve</h2>
          <Row className="g-4">
            <Col md={4}>
              <div className="client-type-card">
                <div className="client-icon">
                  <FontAwesomeIcon icon={faIndustry} size="2x" />
                </div>
                <h3>Business & Industry</h3>
                <ul>
                  <li>Companies & Industries</li>
                  <li>Business Houses</li>
                  <li>Warehouses</li>
                  <li>Corporate Offices</li>
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <div className="client-type-card">
                <div className="client-icon">
                  <FontAwesomeIcon icon={faLandmark} size="2x" />
                </div>
                <h3>Government & NGO</h3>
                <ul>
                  <li>Government Ministries</li>
                  <li>Government Agencies</li>
                  <li>NGO Organizations</li>
                  <li>Public Institutions</li>
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <div className="client-type-card">
                <div className="client-icon">
                  <FontAwesomeIcon icon={faHome} size="2x" />
                </div>
                <h3>Private & Financial</h3>
                <ul>
                  <li>Private Homes</li>
                  <li>Banking Institutions</li>
                  <li>Financial Houses</li>
                  <li>Private Offices</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Call to Action */}
      <section className="cta-section py-5" style={{
        background: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${process.env.PUBLIC_URL}/images/hero-bg.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
        <Container>
          <Row className="justify-content-center text-center">
            <Col md={8}>
              <h2>Need Professional Security Services?</h2>
              <p className="lead">Contact TUNISCONS today for comprehensive security solutions.</p>
              <a href="/contact" className="btn btn-primary btn-lg">Get Started</a>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Add some custom CSS */}
      <style jsx>{`
        .service-icon {
          font-size: 2em;
          margin-bottom: 1.5rem;
        }
        .client-icon {
          font-size: 2em;
          margin-bottom: 1rem;
          color: var(--bs-primary);
        }
        .hover-card {
          transition: transform 0.3s ease;
        }
        .hover-card:hover {
          transform: translateY(-5px);
        }
        .client-type-card {
          text-align: center;
          padding: 2rem;
          background: white;
          border-radius: 10px;
          box-shadow: 0 2px 15px rgba(0,0,0,0.1);
          height: 100%;
        }
        .client-type-card ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        .client-type-card li {
          margin: 0.5rem 0;
          color: #666;
        }
      `}</style>
    </div>
  );
};

export default Services;
