import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const [contactInfo, setContactInfo] = useState({
    location: '',
    phoneNumbers: '',
    email: ''
  });

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const response = await axios.get('https://tunisconsbackend.onrender.com/api/contact');
        if (response.data) {
          setContactInfo(response.data);
        }
      } catch (error) {
        console.error('Error fetching contact info:', error);
      }
    };

    fetchContactInfo();
  }, []);

  // Format phone numbers from CMS data
  const getPhoneNumbers = () => {
    if (!contactInfo?.phoneNumbers) return [];
    return contactInfo.phoneNumbers.split('\n').filter(phone => phone.trim());
  };

  // Get location lines from CMS data
  const getLocationLines = () => {
    if (!contactInfo?.location) return [];
    return contactInfo.location.split('\n').filter(line => line.trim());
  };

  return (
    <footer className="bg-dark text-light py-5">
      <Container>
        <Row>
          <Col lg={4} md={6} className="mb-4 mb-lg-0">
            <h5>About TUNISCONS</h5>
            <p>
              Professional security and consultancy services providing comprehensive
              solutions for businesses and individuals across Liberia.
            </p>
          </Col>
          <Col lg={4} md={6} className="mb-4 mb-lg-0">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><Link to="/" className="text-light">Home</Link></li>
              <li><Link to="/about" className="text-light">About Us</Link></li>
              <li><Link to="/services" className="text-light">Services</Link></li>
              <li><Link to="/team" className="text-light">Our Team</Link></li>
              <li><Link to="/careers" className="text-light">Careers</Link></li>
              <li><Link to="/contact" className="text-light">Contact</Link></li>
              <li><Link to="/admin" className="text-light">Admin</Link></li>
            </ul>
          </Col>
          <Col lg={4} md={12}>
            <h5>Contact Info</h5>
            <ul className="list-unstyled">
              <li className="mb-2">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
                {getLocationLines().map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </li>
              {getPhoneNumbers().map((phone, index) => (
                <li key={index} className="mb-2">
                  <FontAwesomeIcon icon={faPhone} className="me-2" />
                  {phone}
                </li>
              ))}
              <li className="mb-2">
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                {contactInfo.email}
              </li>
            </ul>
            <div className="social-links mt-3">
              <a href="https://web.facebook.com/people/The-Unit-Security-and-Consultancy-Services-Tuniscons-Inc/100063862278754/" className="text-light me-3" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a 
                href="https://wa.me/+231886533792" 
                className="text-light" 
                target="_blank" 
                rel="noopener noreferrer"
                title="Chat with us on WhatsApp"
              >
                <FontAwesomeIcon icon={faWhatsapp} />
              </a>
            </div>
          </Col>
        </Row>
        <hr className="my-4" />
        <Row>
          <Col className="text-center">
            <p className="mb-0">
              &copy; {new Date().getFullYear()} TUNISCONS. All rights reserved. | Developed by <a 
                href="https://dmj-portfolio.web.app/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-light text-decoration-underline"
              >
                David Jallah
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
