import React from 'react';
import Hero from '../components/Hero';
import ServicesPreview from '../components/ServicesPreview';
import AboutPreview from '../components/AboutPreview';
import Statistics from '../components/Statistics';
import ClientsSection from '../components/ClientsSection';
import ContactForm from '../components/ContactForm';

const Home = () => {
  return (
    <div className="home">
      <Hero />
      <ServicesPreview />
      <AboutPreview />
      <Statistics />
      <ClientsSection />
      <ContactForm />
    </div>
  );
};

export default Home;
