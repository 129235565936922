import React from 'react';
import { Nav, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHome,
  faInfoCircle,
  faTools,
  faUsers,
  faBuilding,
  faInfo,
  faBriefcase,
  faEnvelope,
  faPhone,
  faImages,
  faArrowLeft,
  faBars
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const AdminSidebar = ({ activeTab, setActiveTab, sidebarOpen }) => {
  return (
    <div className={`sidebar bg-dark text-white ${sidebarOpen ? 'open' : 'closed'}`} 
         style={{ 
           minWidth: sidebarOpen ? '250px' : '0',
           maxWidth: sidebarOpen ? '250px' : '0',
           transition: 'all 0.3s'
         }}>
      <div className="p-3">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h5 className="mb-0">Admin Panel</h5>
          <Link to="/" className="text-white">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
        </div>
        <ListGroup>
          <ListGroup.Item
            action
            active={activeTab === 'about'}
            onClick={() => setActiveTab('about')}
          >
            <FontAwesomeIcon icon={faInfoCircle} className="me-2" /> About
          </ListGroup.Item>
          <ListGroup.Item
            action
            active={activeTab === 'services'}
            onClick={() => setActiveTab('services')}
          >
            <FontAwesomeIcon icon={faTools} className="me-2" /> Services
          </ListGroup.Item>
          <ListGroup.Item
            action
            active={activeTab === 'team'}
            onClick={() => setActiveTab('team')}
          >
            <FontAwesomeIcon icon={faUsers} className="me-2" /> Team
          </ListGroup.Item>
          <ListGroup.Item
            action
            active={activeTab === 'careers'}
            onClick={() => setActiveTab('careers')}
          >
            <FontAwesomeIcon icon={faBriefcase} className="me-2" /> Careers
          </ListGroup.Item>
          <ListGroup.Item
            action
            active={activeTab === 'gallery'}
            onClick={() => setActiveTab('gallery')}
          >
            <FontAwesomeIcon icon={faImages} className="me-2" /> Gallery
          </ListGroup.Item>
          <ListGroup.Item
            action
            active={activeTab === 'contact'}
            onClick={() => setActiveTab('contact')}
          >
            <FontAwesomeIcon icon={faEnvelope} className="me-2" /> Contact
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  );
};

export default AdminSidebar;
