import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [formStatus, setFormStatus] = useState({ show: false, message: '', type: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contactInfo, setContactInfo] = useState(null);

  useEffect(() => {
    // Initialize EmailJS with your public key
    emailjs.init("glkuQ4tvzHWUooeoU");
    
    // Fetch contact information from CMS
    const fetchContactInfo = async () => {
      try {
        // Production URL
        const response = await axios.get('https://tunisconsbackend.onrender.com/api/contact');
        if (response.data) {
          setContactInfo(response.data);
        }
      } catch (error) {
        console.error('Error fetching contact info:', error);
      }
    };

    fetchContactInfo();
  }, []);

  const headerStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${process.env.PUBLIC_URL}/images/back-background.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingTop: '160px',  
    paddingBottom: '80px',
    marginTop: '0'
  };

  // Format phone numbers from CMS data
  const getPhoneNumbers = () => {
    if (!contactInfo?.phoneNumbers) return ['Loading...'];
    return contactInfo.phoneNumbers.split('\n').filter(phone => phone.trim());
  };

  // Get location lines from CMS data
  const getLocationLines = () => {
    if (!contactInfo?.location) return ['Loading...'];
    return contactInfo.location.split('\n').filter(line => line.trim());
  };

  const getContactInfoItems = () => [
    {
      icon: faMapMarkerAlt,
      title: 'Our Location',
      content: getLocationLines()
    },
    {
      icon: faPhone,
      title: 'Phone Numbers',
      content: getPhoneNumbers()
    },
    {
      icon: faEnvelope,
      title: 'Email Us',
      content: contactInfo?.email ? [contactInfo.email] : ['Loading...']
    }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      subject: formData.subject,
      message: formData.message
    };

    try {
      await emailjs.send('service_hwr7dl3', 'template_my9q405', templateParams);
      
      setFormStatus({
        show: true,
        message: 'Thank you! Your message has been sent successfully.',
        type: 'success'
      });
      
      // Clear form
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Error:', error);
      setFormStatus({
        show: true,
        message: 'Sorry, there was an error sending your message. Please try again later.',
        type: 'danger'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-page">
      {/* Page Header */}
      <header className="page-header" style={headerStyle}>
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              <h1>Contact Us</h1>
              <p className="lead">Get in touch with our security experts</p>
            </Col>
          </Row>
        </Container>
      </header>

      {/* Contact Information */}
      <section className="contact-info py-5">
        <Container>
          <Row className="g-4">
            {getContactInfoItems().map((info, index) => (
              <Col md={4} key={index}>
                <div className="contact-card text-center">
                  <FontAwesomeIcon icon={info.icon} className="mb-3" />
                  <h3>{info.title}</h3>
                  {info.content.map((line, i) => (
                    <p key={i} className="mb-0">{line}</p>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      {/* Contact Form */}
      <section className="contact-form py-5 bg-light">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <Card className="shadow-sm">
                <Card.Body className="p-5">
                  <h2 className="text-center mb-4">Send Us a Message</h2>
                  <Form onSubmit={handleSubmit}>
                    <Row className="g-3">
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label>Subject</Form.Label>
                          <Form.Select
                            name="subject"
                            value={formData.subject}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Select a subject</option>
                            <option value="general">General Inquiry</option>
                            <option value="quote">Request a Quote</option>
                            <option value="support">Technical Support</option>
                            <option value="emergency">Emergency Services</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Label>Message</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={5}
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12}>
                        <Button 
                          type="submit" 
                          variant="primary" 
                          className="w-100"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Sending...' : 'Send Message'}
                        </Button>
                        {formStatus.show && (
                          <div className={`alert alert-${formStatus.type} mt-3`}>
                            {formStatus.message}
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Contact;
